const initialState = {
  outcome: [],
  status: [],
  token: null,
  role: null,
  name: null,
  interviewType: [],
  jobRoleData: [],
  source: [],
  gender: [],
  image: null,
};
const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case "outcome_dropdown": {
      newState.outcome = action.outcomeTypeData;
      break;
    }
    case "status_dropdown": {
      newState.status = state.status.concat(action.value);
      break;
    }
    case "gender_dropdown": {
      newState.gender = action.genderData;
      break;
    }
    case "job_role_dropdown": {
      newState.jobRoleData = action.jobRoleDataValue;
      break;
    }
    case "source_dropdown": {
      newState.source = action.sourceData;
      break;
    }
    case "set_token": {
      newState.token = action.token;
      newState.role = action.role;
      newState.name = action.name;
      newState.image = action.image;
      newState.avatarLetter = action.avatarLetter;
      break;
    }
    case "log_out": {
      newState.token = null;
      break;
    }
    case "interviewer_type": {
      newState.interviewType = action.interviewTypeData;
      break;
    }
    default:
      break;
  }
  return newState;
};
export default reducer;
