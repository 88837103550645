import axios from "axios";
import { url } from "../constants";
export function LoginApi(values) {
  return axios
    .post(url + "/login/", {
      username: values.userName,
      password: values.password
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}

export function exchangeToken(data) {
  return axios
    .post(url+"/api/1.0/social/google-oauth2/",{
      "access_token": data
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
