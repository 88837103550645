import React, { Component } from "react";
import { Modal, Form, Input, Row, Col } from "antd";
import FormItem from "antd/lib/form/FormItem";
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageVisibility: this.props.pageVisibility
    };
  }
  componentWillReceiveProps() {
    this.setState({
      pageVisibility: this.props.pageVisibility
    });
  }
  handleOk = () => {
    this.props.makeFalse(false);
    this.setState({
      pageVisibility: false
    });
  };
  handleCancel = () => {
    this.props.makeFalse(false);
    this.setState({
      pageVisibility: false
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Modal
          title="Change password"
          visible={this.state.pageVisibility}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form>
            <Row>
              <Col span={8}>New Password*</Col>
              <Col>
                <FormItem>
                  {getFieldDecorator("newPassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your New password !"
                      }
                    ]
                  })(
                    <Input placeholder="New Password" className="inputstyle" />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Confirm Password*</Col>
              <Col>
                <FormItem>
                  {getFieldDecorator("confirmPassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Confirm password!"
                      }
                    ]
                  })(
                    <Input
                      placeholder="Confirm Password"
                      className="inputstyle"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default Form.create()(ChangePassword);
