import React, { Component, lazy, Suspense } from "react";
import HeaderComponent from "./header";
import "./App.css";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
const DashboardTab = lazy(() => import("./dashboard_tab"));
const CandidateDetails = lazy(() => import("./edit_candidate"));
const CandidateProfile = lazy(() => import("./candidate_profile"));
const LoginComponent = lazy(() => import ("./login") );
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Router>
        <div className="container">
          <HeaderComponent />
          <Switch>
            <Suspense
              fallback={
                <div className="loding">
                  <h2>Loading...</h2>
                </div>
              }
            >
              <LoginRoute
                path="/"
                exact
                component={LoginComponent}
                token={this.props.token}
              />
              <PrivateRoute
                path="/dashboard"
                component={DashboardTab}
                token={this.props.token}
                exact
                strict
              />
              <PrivateRoute
                path="/dashboard/profile"
                component={CandidateProfile}
                token={this.props.token}
                exact
                strict
              />
              <PrivateRoute
                path="/dashboard/updates"
                component={CandidateDetails}
                token={this.props.token}
                exact
                strict
              />
            </Suspense>
          </Switch>
        </div>
      </Router>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.token
  };
};
export default connect(mapStateToProps)(App);
const PrivateRoute = ({ component: Component, token: Token, ...rest }) => (
  <Route
    {...rest}
    render={props => (Token ? <Component {...props} /> : <Redirect to="/" />)}
  />
);
const LoginRoute = ({ component: Component, token: tokenStorage, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      tokenStorage ? <Redirect to="/dashboard" /> : <Component {...props} />}
  />
)