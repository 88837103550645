import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import InnoventesLogo from "./images/InnoLogo.png";
import { currentPage } from "./current_page";
import ChangePassword from "./change_password";
import { connect } from "react-redux";
import * as Authenticate from "./store/actions/action";
import { Avatar, Icon, Menu, Dropdown, Row, Col } from "antd";
class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordFlag: false
    };
  }
  logout = () => {
    this.props.logOut();
    currentPage.setPageValue(1);
    this.props.history.push("/");
  };
  changePassword = () => {
    this.setState({ passwordFlag: true });
  };
  makeFalse = () => {
    this.setState({ passwordFlag: false });
  };
  render() {
    const menu = (
      <Menu>
        <Menu.Item onClick={this.changePassword}>Change password</Menu.Item>
        <Menu.Item onClick={this.logout}>Logout</Menu.Item>
      </Menu>
    );
    return (
      <div className="head">
        {this.state.passwordFlag === true ? (
          <ChangePassword
            pageVisibility={this.state.passwordFlag}
            makeFalse={this.makeFalse}
          />
        ) : null}
        <Row>
          <Col span={2}>
            <img src={InnoventesLogo} alt="INNOVENTES" />
          </Col>
          {this.props.location.pathname !== "/" ? (
            <div>
              <Col span={2} offset={18}>
                <Icon type="bell" className="iconSize" />
              </Col>
              <Col span={2}>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <a className="ant-dropdown-link" href="location:3000">
                    {this.props.image === null ? (
                      <Avatar>{this.props.name.charAt(0)}</Avatar>
                    ) : (
                      <Avatar src={this.props.image} alt="Loading" />
                    )}
                    {this.props.name}
                  </a>
                </Dropdown>
              </Col>
            </div>
          ) : null}
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.token,
    name: state.name,
    avatarLetter: state.avatarLetter,
    image: state.image
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(Authenticate.logOut())
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderComponent)
);
