import {
  getInterviewTypeList,
  getOutcomesList,
  RetrieveCandidateStatus,
  RetrieveJobPosition,
  Source,
  Gender
} from "../../apiFiles/candidate";
import { LoginApi, exchangeToken } from "../../apiFiles/login";

export const outcomeAsyn = outcomeTypeData => {
  return { type: "outcome_dropdown", outcomeTypeData: outcomeTypeData };
};
export const outcome = () => outcomeDispatch => {
  return getOutcomesList().then(response => {
    outcomeDispatch(outcomeAsyn(response.data.results));
  });
};
export const statusAsyn = val => {
  return { type: "status_dropdown", value: val };
};
export const statusAction = () => statusDispatch => {
  return RetrieveCandidateStatus().then(response => {
    statusDispatch(statusAsyn(response.data.results));
  });
};
export const genderAsyn = genderValue => {
  return { type: "gender_dropdown", genderData: genderValue };
};
export const genderAction = () => genderDispatch => {
  return Gender().then(response => {
    genderDispatch(genderAsyn(response.data.results));
  });
};
export const sourceAsyn = sourceDataValue => {
  return { type: "source_dropdown", sourceData: sourceDataValue };
};
export const sourceAction = () => sourceDispatch => {
  return Source().then(response => {
    sourceDispatch(sourceAsyn(response.data.results));
  });
};
export const jobRoleAsyn = jobRoleData => {
  return { type: "job_role_dropdown", jobRoleDataValue: jobRoleData };
};
export const jobRoleAction = () => jobRoleDispatch => {
  return RetrieveJobPosition().then(response => {
    jobRoleDispatch(jobRoleAsyn(response.data.results));
  });
};
export const loginAuthenticate = data => {
  return dispatch => {
    return LoginApi(data).then(response => {
      const loginResponse = {
        type: "set_token",
        token: response.data.key,
        role: response.data.user.groups[0].name,
        name: response.data.user.first_name,
        image: null,
      }
      dispatch(authenticateUser(loginResponse));
    });
  };
};
export const googleSignIn = (name,accessToken,image) =>{
  return dispatch => {
    return exchangeToken(accessToken).then(response =>{
      const exchangeTokenResponse={
        type: "set_token",
        token:response.data.token,
        role: "HR",
        name: name,
        image: image,
      }
      dispatch(authenticateUser(exchangeTokenResponse));
    })
  }
}
export const authenticateUser = (responseData) => {
  return {
    type : responseData.type, 
    token: responseData.token,
    role : responseData.role,
    name : responseData.name,
    image: responseData.image,
  }
}
export const logOut = () => {
  return { type: "log_out" };
};
export const interviewTypeAsyn = interviewTypeData => {
  return { type: "interviewer_type", interviewTypeData: interviewTypeData };
};
export const getInterviewTypes = () => interviewTypeDispatch => {
  return getInterviewTypeList().then(response => {
    interviewTypeDispatch(interviewTypeAsyn(response.data.results));
  });
};
