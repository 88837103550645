import axios from "axios";
import { url } from "../constants";
var token = null;
export function getAuthorization(key) {
  token = key;
}
export function getAllCandidates(index) {
  return axios
    .get(url + `/api/1.0/candidates/?limit=10&offset=${index}`, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function searchCandidates(text, index) {
  return axios
    .get(url + `/api/1.0/candidates/?name=${text}&limit=10&offset=${index}`, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function RetrieveCandidateStatus() {
  return axios
    .get(url + "/api/1.0/ref/candidatestatus/", {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function RetrieveJobPosition() {
  return axios
    .get(url + "/api/1.0/ref/jobpositions/", {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function Gender() {
  return axios
    .get(url + "/api/1.0/ref/genders/", {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function Source() {
  return axios
    .get(url + "/api/1.0/ref/profilesources/", {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function getCountry() {
  return axios
    .get(url + "/api/1.0/ref/countries/", {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function getStateProvince() {
  return axios
    .get(url + "/api/1.0/ref/states/", {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function getCandidateSkill() {
  return axios
    .get(url + "/api/1.0/ref/skills/", {
      headers: {
        Authorization: "Token  " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function createProfileApi(data) {
  return axios
    .post(url + "/api/1.0/candidates/", data, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function createCandidateCtc(data) {
  return axios
    .post(url + "/api/1.0/candidatectc/", data, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function createCandidateSkill(data) {
  return axios
    .post(url + "/api/1.0/candidateskills/", data, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function getInterviewTypeList() {
  return axios
    .get(url + "/api/1.0/ref/interviewtypes/", {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function getOutcomesList() {
  return axios
    .get(url + "/api/1.0/ref/assessmentoutcomes/", {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function getCandidate(id) {
  return axios
    .get(url + `/api/1.0/candidates/${id}/`, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function postInterviewDetails(InterviewDetails) {
  return axios
    .post(url + `/api/1.0/candidateinterviews/`, InterviewDetails, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function updateCandidate(id, data) {
  return axios
    .patch(url + `/api/1.0/candidates/${id}/`, data, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function deleteCandidate(id) {
  return axios

    .delete(url + `/api/1.0/candidates/${id}/`, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function updateCtcDetails(id, data) {
  return axios
    .patch(url + `/api/1.0/candidatectc/${id}/`, data, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function createFileApi(data) {
  return axios
    .post(url + `/api/1.0/candidatefiles`, data, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function deleteCandidateSkill(id) {
  return axios
     .delete(
       url+`/api/1.0/candidateskills/${id}/`,
       {
         headers: {
           Authorization: "Token " + token
         }
       }
     )
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error;
     });
 }
 export function updateCandidateSkill(id, data) {
   return axios
     .patch(url + `/api/1.0/candidateskills/${id}/`, data, {
       headers: {
         Authorization: "Token " + token
       }
     })
     .then(function(response) {
       return response;
     })
     .catch(function(error) {
       return error;
     });
 }
 export function filterApi(statusValue, sourcevalue, jobvalue) {
  return axios
    .get(
      url +
        `/api/1.0/candidates/?source=${sourcevalue}&candidate_status=${statusValue}&job_position=${jobvalue}`,
      {
        headers: {
          Authorization: "Token " + token
        }
      }
    )
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}
export function getAllCandidatesDetails(index) {
  return axios
    .get(url + `/api/1.0/candidates/?limit=${index}`, {
      headers: {
        Authorization: "Token " + token
      }
    })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
}